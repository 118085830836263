import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a716c8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "field-value" }
const _hoisted_3 = { class: "signatories__section" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "modal__button" }
const _hoisted_7 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownComponent = _resolveComponent("DropdownComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.$t('extension.interaction.field')), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.actionStore.actionMergeField), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.$t('extension.interaction.selectLabel')), 1),
      (_ctx.signatories.length != 0)
        ? (_openBlock(), _createBlock(_component_DropdownComponent, {
            key: 0,
            class: "signatories__dropdown",
            listItem: _ctx.formattedSignatories,
            customId: 'recipient',
            onSelectedValue: _ctx.updatemergeField
          }, null, 8, ["listItem", "onSelectedValue"]))
        : (!_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Pas de signataire"))
          : (_openBlock(), _createElementBlock("p", _hoisted_5, "Chargement des signataires..."))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        class: "gnx-btn _primary",
        disabled: _ctx.signatories.length == 0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyExtension()))
      }, _toDisplayString(_ctx.$t('copy')), 9, _hoisted_7)
    ])
  ], 64))
}