import { environment } from '@/environment';
import { UserToken, InstalledSubscriberPackageRecord } from '@/types/user-token.type';
import axios from 'axios';

/**
 * Gestion de la connexion à Salesforce via le flow OAuth2
 * https://help.salesforce.com/s/articleView?id=sf.remoteaccess_oauth_web_server_flow.htm&type=5
 */

export class AuthenticationService {
	/**
	 * Récupération des infos sur l'org du client stockées dans le Session Storage
	 * @returns Infos sur l'org
	 */
	static async getUserInfos(): Promise<UserToken> {
		const userInfosStr = sessionStorage.getItem('UserInfos');

		const userInfos: UserToken = userInfosStr
			? JSON.parse(userInfosStr)
			: {
					access_token: '',
					id: '',
					instance_url: 'https://login.salesforce.com',
					issue_at: '',
					scope: '',
					signature: '',
					token_type: '',
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  };

		return userInfos;
	}

	static checkAuthentication(): boolean {
		const userInfosStr = sessionStorage.getItem('UserInfos');

		if (userInfosStr) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Récupération de l'access token du client
	 * @param salesforceCode : Code d'authorisation Salesforce
	 * @param baseUrl : Base de l'URL, définie selon le type d'org de l'utilisateur
	 * @returns Réponse de Salesforce avec l'Access Token de l'utilisateur, ou null si erreur
	 */
	static async getUserToken(salesforceCode: string | null, baseUrl: string): Promise<UserToken> {
		return await axios
			.post(
				`${environment.proxyEndpoint}/Proxy/Salesforce/request`,
				{
					type: 'POST',
					url: `${baseUrl}/services/oauth2/token?grant_type=authorization_code&redirect_uri=${location.origin}/callback&client_id=${environment.clientId}&client_secret=${environment.clientSecret}&code=${salesforceCode}`,
				},
				{
					headers: {
						'Content-type': 'application/json',
						ClientId: environment.clientId,
						clientSecret: environment.clientSecret,
					},
				}
			)
			.then(({ data }) => {
				return data;
			})
			.catch((error) => {
				console.error('Erreur dans la récupération des infos de connexion du client : ' + error);
				return null;
			});
	}

	/**
	 * Récupération du nom du Document Template lié à l'Assistant
	 * @param documentTemplateId : Id du Document Template depuis lequel l'Assistant Web a été ouvert
	 * @returns : Nom du Document Template
	 */
	static async getTemplateName(documentTemplateId: string | null): Promise<string> {
		const userInfos: UserToken = await this.getUserInfos();

		// Requête pour récupérer le nom du DT
		const query = `select+Name+from+gnx__Document_template__c+where+Id='${documentTemplateId}'`;

		// Retourne la réponse de l'appel API ou null
		return axios
			.post(
				`${environment.proxyEndpoint}/Proxy/Salesforce/request`,
				{
					type: 'GET',
					url: `${userInfos.instance_url}/services/data/v55.0/query/?q=${query}`,
				},
				{
					headers: {
						Authorization: 'Bearer ' + userInfos.access_token,
						'Content-type': 'application/json',
					},
				}
			)
			.then(({ data }) => {
				if (data.records.length > 0) {
					return data.records[0].Name;
				} else {
					return '';
				}
			})
			.catch((error) => {
				console.error("Erreur dans l'appel du nom de l'enregistrement " + documentTemplateId + ' : ' + error);
				return '';
			});
	}
}
