
import { defineComponent } from 'vue';
import { environment } from '@/environment';

export default defineComponent({
	name: 'InitSalesforce',

	mounted() {
		const urlParams = window.location.search;
		const isSandbox = new URLSearchParams(urlParams).get('is_sandbox');
		const documentTemplateId = new URLSearchParams(urlParams).get('document_template');
		const packageVersion = new URLSearchParams(urlParams).get('package_version');
		const sign = new URLSearchParams(urlParams).get('sign');

		if (sign) {
			sessionStorage.setItem('Sign', sign);
		} else {
			// Si non indiqué dans l'url, on met une valeur vide pour afficher la partie signature au client pré-version 2.2
			sessionStorage.setItem('Sign', '');
		}

		if (packageVersion) {
			sessionStorage.setItem('PackageVersion', packageVersion);
		} else {
			// Si non indiqué dans l'url, on met une valeur inférieur à 2.2 pour ne pas afficher la partie interaction au client pré-version 2.2
			sessionStorage.setItem('PackageVersion', '0');
		}

		if (isSandbox && documentTemplateId) {
			sessionStorage.setItem('IsSandbox', isSandbox);
			sessionStorage.setItem('DocumentTemplateId', documentTemplateId);
			const baseUrl = isSandbox == 'true' ? environment.testUrl : environment.prodUrl;
			window.location.replace(
				`${baseUrl}/services/oauth2/authorize?response_type=code&client_id=${environment.clientId}&redirect_uri=${location.origin}/callback`
			);
		} else {
			console.error(this.$t('error.401'));
			this.$router.push('/fr/error/401');
		}
	},
});
