
import { Vue, Options } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { useFieldsActionsStore } from '@/stores/fields-actions.store';

import DropdownComponent from '@/components/components/DropdownComponent.vue';

import SObjectsService from '@/services/salesforce-objects.service';
import { AuthenticationService } from '@/services/authentication.service';
import { GnxObject, Signatories } from '@/types/salesforce-objects.type';

@Options({
	components: {
		DropdownComponent,
	},
})
export default class InteractionExtension extends Vue {
	toast = useToast();
	actionStore = useFieldsActionsStore();
	mergeField = '';
	isLoading = true;

	sObjects: GnxObject[] = [];

	signatories = [] as Signatories[];
	formattedSignatories = [] as { Value: string; Label: string }[];

	async mounted() {
		// Check s'il y a un utilisateur authentifié : si oui, on affiche sa page normalement, sinon, on le redirige vers la page d'erreur lui indiquant qu'il faut qu'il passe par Sf pour s'authentifier et utiliser l'Assistant
		const checkAuth = AuthenticationService.checkAuthentication();
		if (!checkAuth) {
			this.$router.push(`/${this.$route.params.lang}/error/401`);
		}

		const documentTemplateId = sessionStorage.getItem('DocumentTemplateId');

		if (documentTemplateId) {
			// Check si des signataires sont configurés et doivent être affichés
			await SObjectsService.getRecipientsConfiguration(documentTemplateId)
				.then((response) => {
					const data = JSON.parse(response).data;
					this.signatories = data;
					this.formattedSignatories = data.map((item: { type: string; order: number }) => ({
						Label: item.type ? item.type : item.order.toString(),
						Value: item.order.toString(),
					}));
				})
				.catch(() => {
					this.signatories = [];
					this.formattedSignatories = [];
				});
		}

		this.isLoading = false;
	}

	/**
	 * Mettre à jour mergeField avec la valeur selectionnée.
	 * @param selectedValue : Objet containant le label et la valeur choisi.
	 */
	updatemergeField(selectedValue: { label: string; value: string }) {
		const mf = this.actionStore.actionMergeField;
		this.mergeField = `{!s${selectedValue}|${mf.substring(2)}`;
	}

	public copyExtension() {
		try {
			navigator.clipboard.writeText(this.mergeField);
			this.toast.success(this.$t('mergeFields.copied'));
		} catch (error) {
			this.toast.error(this.$t('mergeFields.notCopied'));
		}
	}
}
