
import { Vue, Options } from 'vue-class-component';
import { useToast } from 'vue-toastification';

import HeaderView from '@/components/pages/HeaderView.vue';
import PopoverComponent from '../components/PopoverComponent.vue';

import SObjectsService from '@/services/salesforce-objects.service';
import { AuthenticationService } from '@/services/authentication.service';

import { useFieldsHistoryStore } from '@/stores/fields-history.store';
import { useSalesforceFieldstore } from '@/stores/salesforce-fields.store';

import { GnxObject, ParentObject, ChildObject, Signatories } from '@/types/salesforce-objects.type';

@Options({
	components: {
		HeaderView,
		PopoverComponent,
	},
})
export default class DashboardView extends Vue {
	sObjects: GnxObject[] = [];
	parentObjects: ParentObject[] = [];
	otherObjects: ChildObject[] = [];
	toast = useToast();
	loadingPage = true;

	historyStore = useFieldsHistoryStore();
	sfStore = useSalesforceFieldstore();

	isMainObject = false;
	noObjects = false;
	signatories = [] as Signatories[];

	async beforeMount() {
		// Check s'il y a un utilisateur authentifié : si oui, on affiche son tableau de bord normal, sinon, on le redirige vers la page d'erreur lui indiquant qu'il faut qu'il passe par Sf pour s'authentifier et utiliser l'Assistant
		const checkAuth = AuthenticationService.checkAuthentication();
		if (!checkAuth) {
			this.$router.push(`/${this.$route.params.lang}/error/401`);
		}

		const documentTemplateId = sessionStorage.getItem('DocumentTemplateId');

		if (documentTemplateId) {
			// Insertion de tous les objets liés au document template dans le tableau sfObjects
			await SObjectsService.getAllSObjects(documentTemplateId).then((response) => {
				this.sObjects = response;
				if (response.length == 0) this.noObjects = true;
			});

			// Check si des signataires sont configurés et doivent être affichés
			await SObjectsService.getRecipientsConfiguration(documentTemplateId)
				.then((response) => {
					this.signatories = JSON.parse(response).data;
				})
				.catch(() => {
					this.signatories = [];
				});
		}

		this.parentObjects = this.insertParentObjects(this.sObjects);

		// Ajout des objets enfants à chaque objet parent
		this.parentObjects.forEach((parentObj) => {
			this.sObjects.forEach((childObject) => {
				if (childObject.gnx__parent_object__c == parentObj.id) {
					if (
						!childObject.gnx__Lookup_name_on_child_object__c ||
						childObject.gnx__Lookup_name_on_child_object__c.includes('.')
					) {
						// ORPHELIN OU PETIT-ENFANT
						this.otherObjects.push({
							id: childObject.Id,
							name: childObject.Name,
							apiName: childObject.gnx__Object_API_name__c,
							parentObject: parentObj,
						});
					} else {
						// ENFANT
						parentObj.childObjects.push({
							id: childObject.Id,
							name: childObject.Name,
							apiName: childObject.gnx__Object_API_name__c,
							parentObject: parentObj,
						});
					}
				}
			});
		});

		this.loadingPage = false;
	}

	mounted() {
		this.historyStore.clearHistoric();
		this.sfStore.clearSalesforceFields();
	}

	public goToFieldsList(sObjectApiName: string, sObjectName: string) {
		this.$router.push(`/${this.$route.params.lang}/merge-fields/${sObjectApiName}/${sObjectName}`);
	}

	public goToSign(order: number, name: string) {
		this.$router.push({
			path: `/${this.$route.params.lang}/signature/${order}`,
			query: { name: name },
		});
	}

	// Insertion des objets parents dans le tableau parentObjects
	private insertParentObjects(allObjects: GnxObject[]): ParentObject[] {
		let parents: ParentObject[] = [];

		allObjects.forEach((obj) => {
			const parentObject = allObjects.find((o) => o.Id === obj.gnx__parent_object__c);

			if ((!obj.gnx__Lookup_name_on_child_object__c && !obj.gnx__parent_object__c) || !parentObject) {
				// Définit si l'objet parent est l'objet principale ou un objet secondaire
				this.isMainObject = !obj.gnx__Get_record_Id_from_custom_query__c ? true : false;
				parents.push({
					id: obj.Id,
					name: obj.Name,
					apiName: obj.gnx__Object_API_name__c,
					isMainObject: this.isMainObject,
					childObjects: [],
				});
			}
		});

		// Tri du tableau des objets parents si nécessaire : Objet principal en première place
		parents.sort(function (x, y) {
			return x.isMainObject ? -1 : y.isMainObject ? 1 : 0;
		});

		return parents;
	}
}
